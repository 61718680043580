<template>
  <v-row
    no-gutters
    class="gestion_des_licences__dashboard-layout"
    :class="{ loading: loading }"
  >
    <v-col cols="12" lg="8" class="mx-auto">
      <v-row style="height: 100%;">
        <v-col cols="12" class="p-4 licences-adherents-content">
          <v-row style="height: 100%;">
            <v-col cols="6">
              <card
                module_name="app_settings__gestion_des_structures"
                title_card="Gestion des Structures"
                ID_User="currentUserId"
              />
            </v-col>

            <v-col cols="6">
              <card
                module_name="app_settings__playground"
                title_card="Playground"
                ID_User="currentUserId"
              />
            </v-col>

            <v-col cols="6">
              <!-- <card 
                    module_name="gestion_des_licences__saisir_une_licence_decouverte" 
                    title_card="Saisir une licence découverte" 
                    ID_User="currentUserId" /> -->
            </v-col>

            <v-col cols="6">
              <!-- <card 
                    module_name="gestion_des_licences__saisir_les_licences_via_csv" 
                    title_card= "Saisir les licences via fichier CSV" 
                    ID_User="currentUserId" /> -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import card from "@/components/Common/DashboardCard.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "GestionDesLicences__Dashboard",

  components: { card },

  data: () => ({
    loading: true,
  }),
  async created() {
    this.setAppTitle({ title: "Settings" });

    this.loading = false;
  },

  methods: {
    ...mapActions("app", ["setAppTitle"]),
  },

  computed: {
    ...mapGetters("user", ["currentUserId"]),
  },
};
</script>

<style lang="scss">
.gestion_des_licences__dashboard-layout {
  position: relative;
  height: calc(100vh - 80px);
  &.loading {
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
    }
  }
}
</style>
