<template>
  <div>
    <v-file-input v-model="file" @change="readCSV"></v-file-input>Résultat:
    <ul>
      <li v-for="duplicate in duplicates" :key="duplicate.ligne">
        Un utilisateur éxiste déjà dans la base de donnée actuelle: Ligne
        {{ duplicate.ligne }} du csv, utilisateur:
        {{ duplicate.user.CT_Nom + " " + duplicate.user.CT_Prenom }}
      </li>
    </ul>
  </div>
</template>

<script>
import Papa from "papaparse";
import { mapActions } from "vuex";
export default {
  components: {},
  data: () => ({
    file: null,
    duplicates: [],
  }),

  async created() {},
  methods: {
    ...mapActions("playground", ["checkUser"]),

    readCSV() {
      if (this.file) {
        Papa.parse(this.file, {
          complete: (result) => {
            // console.log(result);
            result.data.shift();
            if (result.data[result.data.length - 1].length === 1)
              result.data.pop();
            this.checkUsers(result.data);
          },
        });
      } else {
        this.duplicates = [];
      }
    },
    async checkUsers(users) {
      await this.asyncForEach(users, async (user, i) => {
        const lastname = user[1];
        const firstname = user[2];
        const birthday = user[3];
        let res = await this.checkUser({ lastname, firstname, birthday });
        if (res.data.UTI_Utilisateurs.length)
          this.duplicates.push({
            ligne: i + 2,
            user: res.data.UTI_Utilisateurs[0],
          });
      });
    },
    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    },
  },
};
</script>

<style></style>
